<template>
  <div>
    <div class="footer-wrap" v-if="!isPc">
      <span class="copyright-text"
        >Copyright ©2021-2031 安徽儒彧电子信息科技有限公司版权所有</span
      >
    </div>
    <section class="footer-container" v-else>
      <div class="footer-bottom">
        <div class="bottom-content type-area">
          <div class="copyright">
            法律声明 | 隐私保护
            <span class="copyright-text"
              >Copyright © 2021-2031 安徽儒彧电子信息科技有限公司版权所有</span
            >
            <a
              class="copy"
              style="margin-left:10px"
              target="_blank"
              href="https://beian.miit.gov.cn"
              >皖ICP备20002280号-1</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["isPc"],
  data() {
    return {};
  },

  methods: {},

  created() {}
};
</script>

<style lang="less" scoped>
@import "../../../styles/footerPc.less";
.footer-wrap {
  color: #ffffff;
  padding: 40px 0;
  background-color: #383838;
  text-align: center;
  .copyright-text {
    font-size: 24px;
  }
}
</style>
