import Vue from "vue";

import {
    Toast,
    Button,
    Swipe,
    SwipeItem,
    DropdownMenu,
    DropdownItem,
    Icon,
    Overlay,
    Tab,
    Tabs,
    List,
    ImagePreview,
    Field
} from "vant";

Vue.use(Toast);
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Icon);
Vue.use(Overlay);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(List);
Vue.use(ImagePreview);
Vue.use(Field);

import { Pagination, Message } from "element-ui";
Vue.use(Pagination);

// 判断使用消息提醒组件
Vue.prototype.$message = window.innerWidth >= 1200 ? Message : Toast;
if (window.innerWidth < 1200) {
    Vue.prototype.$message.error = Vue.prototype.$message.fail;
}
