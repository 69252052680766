import http from "../utils/http";
const navUrl = "/navigationManage/treeBySite";
const featureContentUrl = "/featuresContent/getByCondition";
const swiperUrl = "/gatewayAdvertise/findAll";
const getCodeUrl = "/common/captcha/sendSms";
const codeCommitUrl = "/franchisee/save";
const getAscendnumberUrl = "/get_order_prod_ascend.html";
// 获取手机验证码
export function getCode (params) {
    return http.get(getCodeUrl, params);
}

export function getAscend (params) {
    return http.post(getAscendnumberUrl, params);
}

// 获取菜单
export function getNav (params) {
    return http.get(navUrl, params);
}

// 获取菜单
export function getFeatureContent (params) {
    return http.get(featureContentUrl, params);
}

// 获取swiper
export function getSwiper (params) {
    return http.get(swiperUrl, params);
}

// 提交手机验证码
export function commitCode (data) {
    return http.post(codeCommitUrl, data);
}
