<template>
  <section class="position-container">
    <ul v-if="isPc">
      <li class="qq">
        <img src="../assets/img-pc/qq.png" alt="" />
        <div class="text">
          <a
            target="_blank"
            href="http://wpa.qq.com/msgrd?v=3&uin=1525213860&site=qq&menu=yes"
            >在线咨询</a
          >
        </div>
      </li>
      <li class="phone">
        <img src="../assets/img-pc/phone.png" alt="" />
        <span class="phone-icon">加盟热线：400-8820-919</span>
      </li>
      <li class="weixin">
        <img src="../assets/img-pc/weixin.png" alt="" />
        <div class="weixin-icon">
          <img src="../assets/img-pc/erweima.png" alt="" />
          <div>请扫描二维码</div>
        </div>
      </li>
      <li class="to-top" @click="backTop">
        <img src="../assets/img-pc/top-hui.png" alt="" />
      </li>
    </ul>
    <div class="phone-wrap" v-else @click="call"></div>
  </section>
</template>

<script>
export default {
  props: ["isPc"],
  data() {
    return {
      show: false,
      showOverlay: false
    };
  },
  methods: {
    backTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
    showToast() {
      this.showOverlay = true;
      this.show = true;
    },
    call() {
      this.show = false;
      this.showOverlay = false;
      window.location.href = "tel://400-8820-919";
    }
  },
  computed: {}
};
</script>

<style lang="less" scoped>
@import "../styles/position.less";
.position-container {
  .phone-wrap {
    width: 151px;
    height: 151px;
    background-image: url("../assets/img/call-phone.png");
    background-size: contain;
  }
  .call-wrap {
    width: 60%;
    background-color: #fff;
    position: fixed;
    z-index: 201;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 30px;
    .text-wrap {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 60px;
      font-size: 38px;
      border-bottom: 1px solid #eee;
      span {
        width: 100%;
        text-align: center;
        &.title {
          font-size: 42px;
          font-weight: 700;
          margin-bottom: 20px;
        }
      }
    }
    .buttton-wrap {
      font-size: 42px;
      color: #5cadff;
      display: flex;
      justify-content: space-between;
      span {
        width: 50%;
        text-align: center;
        padding: 30px 0;
        &:last-child {
          border-left: 1px solid #eee;
        }
      }
    }
  }
}
</style>
